@import "assets/scss/color-palette";

.container {
  padding: 12px;
  height: calc(100% - 140px);
  width: 100%;
  position: relative;
  background: $light-gray;
  border: 3px solid $white;
  overflow-y: auto;
  overflow-x: hidden;

  .messages {
    > div:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $light-gray;
  }

  &::-webkit-scrollbar-thumb {
    background: $regent-gray;
  }
}
