@import 'assets/scss/color-palette';

.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;

  :global {
    div.modal-body__bottom {
      border: none;
    }
  }

  .sectionWrapper {
    border: 1px solid $light-porcelain;
  }

  .section {
    display: flex;
    gap: 10px;
    padding: 30px 20px;
  }

  .sectionTitle {
    font-size: 14px;
    font-weight: 600;
  }

  .subTitle {
    margin-top: 5px;
    color: $nevada;
  }

  .timeSelectorWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-right: 25px;
    gap: 40px;
  }

  .dataRow {
    display: flex;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  .rowLeft {
    min-width: 160px;
    font-weight: 600;
  }

  .rowRight {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .infoIcon {
    color: $crusta;
    font-size: 16px;
  }

  .blueBg {
    background-color: $lily-white;
  }

  .borderTop {
    border-top: 1px solid $light-porcelain;
  }

  i {
    font-size: 20px;
    color: $regent-gray;
  }

  .sendTo {
    .emailFrom {
      width: 370px;
    }

    .emailsTo {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      >*:nth-child(1) {
        flex: 1;
      }

      >button {
        margin-left: 5px;
        margin-top: 23px;
      }
    }
  }
}
